<template>
    <div >
        
    </div>
</template>
<script>
import { getFile } from '@/services/product';
export default {
  data() {
    return {
        id:this.$route.params.id?this.$route.params.id:null,
    };
  }, 
  
  created() {

    console.log(this.id)
   



    getFile({
        id:this.id
    }).then(res=>{
        const {code,message,data} = res.data;

        console.log(res)
        if(code === 0){
            console.info(data)
            const url = `https://www.wch.cn/downloads/${data[0].name}.html?type=en`
   
             window.location = url;
        }



    })

   
    


  },
 
};
</script>
<style scoped>

</style>